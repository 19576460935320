import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import logo from '@dashboard/public/static/share/images/crystal_svg_text_logo.svg'
import styles from './Navigation.module.scss'

export default function NavigationPublic() {
  return (
    <nav className={styles.navBar}>
      <div className="nav-areas">
        <div className="primary-bar public">
          <div className="logo-search-area">
            <Link prefetch={false} href="/" passHref aria-label="Homepage">
              <Image
                src={logo.src}
                alt="Crystal Logo"
                width={128}
                height={38}
              />
            </Link>
          </div>

          <div className="login-ctas">
            <div className="links">
              {typeof window !== 'undefined' &&
                window?.location?.href.includes('/app/playbooks') && (
                  <Link prefetch={false} href="/app/playbook-library" passHref>
                    Browse Playbooks
                  </Link>
                )}

              <Link prefetch={false} href="/app/login" passHref>
                Log In
              </Link>
            </div>

            <Link prefetch={false} href="/app/register" passHref>
              Sign Up Free
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}
