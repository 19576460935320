'use client'

import React from 'react'
import NavigationAuthed, { NavigationProps } from './NavigationAuthed'
import NavigationPublic from './NavigationPublic'
import useAuth from '@crystal-eyes/hooks/useAuth'
import SideNavigationAuthed from './side_navigation/SideNavigationAuthed'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'

export default function Navigation(props: NavigationProps) {
  const { data: me } = useMeV2()
  const { authed, loading } = useAuth()

  const sideNavEnabled = me?.sidenavFeatureFlag
  if (loading) return null

  return authed ? (
    <>
      {sideNavEnabled && !props.public ? (
        <SideNavigationAuthed />
      ) : (
        <NavigationAuthed {...props} />
      )}
    </>
  ) : (
    <NavigationPublic />
  )
}
