import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  uncontainedSmall?: boolean
  uncontained?: boolean
  flexFill?: boolean
  children?: React.ReactNode
}

const Container: FC<OwnProps> = ({
  uncontainedSmall,
  uncontained,
  flexFill,
  children,
}: OwnProps) => {
  return (
    <div
      className={classNames('container', {
        uncontainedSmall,
        uncontained,
        flexFill,
      })}
    >
      {children}
    </div>
  )
}

export default Container
